<template>
  <div class="page-network">
    <drawer-checkin-filter
      gaCategory="network"
      :showDrawer="showCheckinFiltersDrawer"
      :numberOfSuggestions="30"
      :hideFilterHeader="true"
      @showHideCheckinFilterDrawer="showHideCheckinFilterDrawer"
      @updateFilterQuery="filterCheckins"
    />

    <nav
      v-if="isLoggedIn && isInitiated"
      class="navbar-custom-menu"
    >
      <div class="navbar-item">
        <router-link
          :to="{ name: 'Dashboard' }"
          class="navbar-link is-arrowless"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Passport</span>
        </router-link>
      </div>
      <div class="navbar-item">
        <router-link
          v-if="locationId"
          :to="previousRoute ? previousRoute : '/'"
          class="navbar-link is-arrowless"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Back</span>
        </router-link>
      </div>
    </nav>
    <hr />
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isPageLoading"
        key="pageLoading"
      >
        <ui-loader />
      </div>
      <div
        v-else
        key="readyPageLoading"
      >
        <nav class="topbar-network level">
          <div class="level-left">
            <div class="title is-3">
              {{
                locationData.Id
                ? `People present at ${locationData.Name}`
                : 'Find people and expertise'
                }}
            </div>
          </div>
          <div class="level-right is-clearfix">
            <a
              v-if="pageView === 'tiles'"
              @click="showHideCheckinFilterDrawer()"
              class="is-hidden-tablet button has-margin-right"
            >
              <span class="icon">
                <i class="fas fa-search"></i>
              </span>
              <span>Search</span>
            </a>

            <transition
              name="fade"
              mode="out-in"
            >
              <a
                class="is-hidden-tablet anchor-clear-filters has-text-danger"
                v-if="
                    $store.getters.getCheckinSearchFilters.searchString !== ''
                  "
                key="clear"
                @click="clearFilters()"
              >
                <span class="icon is-small">
                  <i class="fas fa-times-circle"></i>
                </span>
                <span>Clear search</span>
              </a>
            </transition>

            <transition
              name="fade"
              mode="out-in"
            >
              <button
                v-if="
                    locationId &&
                      pageView === 'tiles' &&
                      (currentCheckin === null ||
                        (currentCheckin && !currentCheckin.LocationId)) &&
                      locationOpeningHours.MaxMinutes >
                        this.$options.filters.timeToMinutes(
                          new Date().getTime()
                        )
                  "
                key="tiles"
                @click="changeView('checkinProcess')"
                class="button is-primary"
              >
                <span class="is-hidden-mobile">Check-in on this location</span>
                <span class="is-hidden-tablet">Check-in</span>
              </button>
              <button
                v-if="locationId && pageView === 'checkinProcess'"
                key="checkinProcess"
                @click="changeView('tiles')"
                class="button is-warning"
              >Close</button>
            </transition>

            <nav
              v-if="!locationId"
              class="is-inline-flex is-pulled-right pagination is-right"
              role="navigation"
              aria-label="pagination"
            >
              <ul class="pagination-list">
                <li>
                  <a
                    @click="changeView('tiles')"
                    class="pagination-link"
                    :class="{ 'is-current': pageView === 'tiles' }"
                  >
                    <span class="icon">
                      <i class="fas fa-th"></i>
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    @click="changeView('map')"
                    class="pagination-link"
                    :class="{ 'is-current': pageView === 'map' }"
                  >
                    <span class="icon">
                      <i class="fas fa-map-marked-alt"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </nav>

        <div v-if="['tiles', 'checkinProcess'].indexOf(pageView) > -1">
          <div class="columns is-multiline">
            <div class="column is-one-third-tablet is-one-quarter-desktop">
              <location-detail
                v-if="locationId"
                :locationData="locationData"
                :openingHours="locationOpeningHours"
                :hideName="true"
                class="has-margin-bottom-x2 is-hidden-mobile"
              />
              <checkin-filter
                v-if="pageView !== 'checkinProcess' && checkins.length"
                gaCategory="network"
                :hideFilterHeader="true"
                :numberOfSuggestions="30"
                :locationId="locationId"
                :hideSearchButton="false"
                @updateFilterQuery="filterCheckins"
                class="is-hidden-mobile"
              />
            </div>

            <div class="column">
              <transition
                name="fade"
                mode="out-in"
              >
                <!-- CHECKIN PROCESS -->
                <div
                  v-if="!isLoading && pageView === 'checkinProcess'"
                  key="readyLoading"
                >
                  <checkin-process
                    class="checkinProcess-holder"
                    @cancelProcess="cancelProcess"
                    @callbackFinished="callbackFinished"
                    :openingHours="locationOpeningHours"
                    :location="locationData"
                    :showMatchesButton="false"
                  ></checkin-process>
                </div>

                <!-- NETWORK VIEW -->
                <div
                  class="columns is-multiline"
                  v-if="!isLoading && pageView !== 'checkinProcess'"
                  key="readyLoading"
                >
                  <div
                    v-if="checkins.length > itemsPerPage"
                    class="column is-full"
                  >
                    <CheckinsPagination
                      :gotoPage="gotoPage"
                      :currentPage="page"
                      :itemsPerPage="itemsPerPage"
                      :pageCount="Math.ceil(checkins.length / itemsPerPage)"
                    />
                  </div>
                  <div class="column is-full">
                    <transition
                      name="slide-fade"
                      mode="out-in"
                    >
                      <!-- Message: Nothing found based on search -->
                      <div v-if="!checkins.length && q && locationId">
                        <div class="notification has-icon is-info has-margin-bottom">
                          <span class="icon notification-icon fa-lg">
                            <i class="fas fa-info-circle"></i>
                          </span>
                          <strong>
                            Sorry, we couldn’t find a match. Maybe tweak the
                            query a bit? Or search in the whole network to
                            find the right expertise!
                          </strong>
                        </div>
                        <div class="buttons is-centered">
                          <router-link
                            :to="{ name: 'Network' }"
                            class="button is-primary"
                          >Find people and knowledge</router-link>
                        </div>
                      </div>
                      <div
                        v-if="!checkins.length && q && !locationId"
                        class="notification has-icon is-info"
                      >
                        <span class="icon notification-icon fa-lg">
                          <i class="fas fa-info-circle"></i>
                        </span>
                        <strong>
                          No people found based on your search
                          request
                        </strong>
                      </div>

                      <!-- Message: No checkins -->
                      <div v-if="!checkins.length && !q">
                        <div
                          v-if="
                              (currentCheckin === null ||
                                (currentCheckin &&
                                  currentCheckin.locationId === 0)) &&
                                locationOpeningHours.MaxMinutes >
                                  this.$options.filters.timeToMinutes(
                                    new Date().getTime()
                                  )
                            "
                          class="has-text-centered"
                        >
                          <figure class="is-inline-flex has-margin-bottom image is-128x128">
                            <img
                              src="@/assets/img/icons/bestversionofyou.png"
                              alt="best version of yourself"
                            />
                          </figure>
                          <div class="subtitle is-2">
                            Hooray!
                            <br />You're the first one to check-in
                          </div>
                          <a
                            class="button is-primary"
                            @click="changeView('checkinProcess')"
                          >
                            <span class="icon">
                              <i class="fas fa-check"></i>
                            </span>
                            <span>Check-in on this location</span>
                          </a>
                        </div>
                        <div v-else>
                          <div class="notification has-icon is-info">
                            <span class="icon notification-icon fa-lg">
                              <i class="fas fa-info-circle"></i>
                            </span>
                            <strong>
                              No people are checked in at this
                              location.
                            </strong>
                          </div>
                        </div>
                      </div>

                      <!-- Has checkins -->
                      <div
                        v-if="checkins.length"
                        class="columns-checkins columns is-multiline is-mobile"
                        :key="'page_' + page + '_' + checkins.length"
                      >
                        <div
                          v-for="(checkin, index) in checkins.slice(
                              (page - 1) * itemsPerPage,
                              (page - 1) * itemsPerPage + itemsPerPage
                            )"
                          :key="'c_' + checkin.Id + '_' + index"
                          class="column is-half-mobile is-half-tablet is-one-third-desktop"
                        >
                          <checkin-item
                            :checkin="checkin"
                            :showTime="true"
                            :showLocation="!Boolean(locationId)"
                            :showWorkingon="true"
                            :showTags="true"
                            :showDistance="showCheckInDistance"
                          ></checkin-item>
                        </div>
                      </div>
                    </transition>
                  </div>

                  <div
                    v-if="checkins.length > itemsPerPage"
                    class="column is-full"
                  >
                    <CheckinsPagination
                      :gotoPage="gotoPage"
                      :currentPage="page"
                      :itemsPerPage="itemsPerPage"
                      :pageCount="Math.ceil(checkins.length / itemsPerPage)"
                    />
                  </div>
                </div>
                <div v-if="isLoading">
                  <ui-loader />
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div v-if="pageView === 'map'">
          <div class="maps-container">
            <visualisation-map
              ref="visualisationMap"
              :checkins="loadedCheckins"
              :mapHeight="'65vh'"
              class="maps"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import checkinProvider from '../providers/checkin'
import locationProvider from '../providers/location'
import openingHoursProvider from '../providers/openinghours'
const DrawerCheckinFilter = () =>
  import('@/components/partial/DrawerCheckinFilter')
const VisualisationMap = () =>
  import(
    /* webpackChunkName: "network" */ '@/components/visualisationMap/VisualisationMap'
  )
const CheckInProcess = () =>
  import(/* webpackChunkName: "network" */ '@/components/CheckInProcess')
const LocationDetail = () =>
  import(
    /* webpackChunkName: "network" */ '@/components/partial/LocationDetail'
  )
const CheckinFilter = () =>
  import(/* webpackChunkName: "network" */ '@/components/partial/CheckinFilter')

import CheckinsPagination from '@/components/UI/CheckinsPagination'

export default {
  name: 'Network',

  components: {
    'checkin-filter': CheckinFilter,
    'drawer-checkin-filter': DrawerCheckinFilter,
    'visualisation-map': VisualisationMap,
    'location-detail': LocationDetail,
    CheckinsPagination,
    'checkin-process': CheckInProcess
  },

  props: {},

  data() {
    return {
      locationData: {},
      locationId: Number(this.$route.params.locationId) || 0,
      locationOpeningHours: {
        NrOfLocations: 0,
        MaxTimeClose: new Date().toISOString(),
        MinTimeOpen: new Date().toISOString(),
        MaxMinutes: 0,
        MinMinutes: 0
      },
      isLoading: true,
      isPageLoading: true,
      routeName: this.$route.name || 'Network',
      q: '',
      page: 1,
      itemsPerPage: 21,
      pageCount: 0,
      checkins: [],
      pageView: this.$route.query.v || 'tiles',
      loadedCheckins: [],
      pagination: [],
      previousRoute: '',
      showCheckinFiltersDrawer: false
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.previousRoute = from.path
    })
  },

  watch: {
    '$route.name': function() {
      this.forceRerender()
    }
  },

  computed: {
    ...mapGetters({
      currentCheckin: 'getCurrentCheckin',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn'
    }),

    showCheckInDistance() {
      return this.currentCheckin === null
        ? false
        : this.currentCheckin.Latitude !== 0 &&
        this.currentCheckin.Longitude !== 0
    },

    locationOpen() {
      let isOpen = false

      let currentTime = this.$options.filters.timeToMinutes(new Date())
      if (
        this.locationOpeningHours.NrOfLocations > 0 &&
        currentTime >= this.locationOpeningHours.MinMinutes &&
        currentTime <= this.locationOpeningHours.MaxMinutes
      ) {
        isOpen = true
      }
      return isOpen
    }
  },

  created() {
    if (this.locationId) {
      this.pageView = 'tiles'
      this.$router.push({ name: 'NetworkLocation' })
    }
    this.getData()
  },

  methods: {
    cancelProcess() {
      this.changeView('tiles')
      this.getCheckins().then(response => {
        this.processCheckinData(response)
      })
    },

    callbackFinished() {
      this.changeView('tiles')
      this.getCheckins().then(response => {
        this.processCheckinData(response)
      })
    },

    /**
     * Change view
     * Values:
     * - checkinProcess
     * - map
     * - tiles
     */
    changeView(v = 'tiles') {
      this.pageView = v
      if (this.locationId) {
        this.$router.push({ name: 'NetworkLocation', query: { v: v } })
      } else {
        this.$router.push({ name: 'Network', query: { v: v } })
      }
      // this.clearFilters()
      this.filterCheckins()
    },

    /**
     * Clear search filter
     */
    clearFilters() {
      this.$store.dispatch('clearCheckinSearchFilters')
      this.filterCheckins()
    },

    /**
     * Load data
     */
    getData() {
      let self = this
      this.isPageLoading = true

      axios
        .all([this.getLocationData(), this.getCheckins()])
        .then(
          axios.spread((locationResponse, checkinResponse) => {
            // Process location data
            if (locationResponse) {
              self.locationData = locationResponse.data
            }

            // Process checkin data
            if (checkinResponse.status === 200) {
              self.processCheckinData(checkinResponse)
            } else {
              self.isLoading = false
            }
          })
        )
        .catch(e => {
          console.info(e)
        })
        .finally(() => {
          self.isPageLoading = false
        })
    },

    /**
     * Get location data
     */
    getLocationData() {
      let self = this

      if (this.locationId) {
        openingHoursProvider.methods
          .getOpeningHours({
            date: new Date(),
            locationId: this.locationId,
            meetingtypeId: 2
          })
          .then(response => {
            self.locationOpeningHours = response.data
          })
        return locationProvider.methods.getLocationByLocationId(this.locationId)
      } else {
        return false
      }
    },

    /**
     * Get checkins
     */
    getCheckins(q = '') {
      this.isLoading = true

      return checkinProvider.methods.getCheckins({
        date: this.$options.filters.dateObjectIsoDateString(new Date()),
        locationId: this.locationId,
        q
      })
    },

    /**
    /**
     * Update page
     */
    gotoPage(page) {
      this.page = page
    },

    /**
     * Filter checkins bases on search query
     */
    filterCheckins(q = '') {
      let checkins = JSON.parse(JSON.stringify(this.loadedCheckins))
      this.q = q

      if (!this.isLoading) {
        this.isLoading = true
        this.checkins = []

        if (q === '') {
          this.checkins = checkins
          this.pageCount = Math.ceil(checkins.length / this.itemsPerPage)
          this.page = 1
          this.isLoading = false
          return
        }

        let searchArr = q.toLowerCase().split(' ')
        let output = []
        let toBeRemoved = []

        // Search checkins bases on search term
        searchArr.find(searchTerm => {
          checkins.forEach((checkin, i) => {
            let str = (checkin.ProfileName + ' ' + checkin.Tags).toLowerCase()
            if (checkin.locationId) {
              str +=
                ' ' +
                (
                  checkin.LocationName +
                  ' ' +
                  checkin.LocationCity
                ).toLowerCase()
            }
            if (str.match(searchTerm.trim()) !== null) {
              toBeRemoved.push(i)
              output.push(checkin)
            }
          })
          // Removed founded checkins
          toBeRemoved.forEach(val => {
            checkins.splice(val, 1)
          })

          // Clean up
          toBeRemoved = []
        })

        this.checkins = output
        this.pageCount = Math.ceil(output.length / this.itemsPerPage)
        this.page = 1

        // Clean up and delete variables
        output = null
        checkins = null
        toBeRemoved = null

        this.isLoading = false
      }
    },

    /**
     * Process checkin data
     * - Remove duplicate checkins
     * - Create locations list from checkins
     */
    processCheckinData(response) {
      this.checkins = this.unDouble(response.data.Results)
      this.loadedCheckins = this.checkins
      this.isLoading = false
      this.pageCount = Math.ceil(this.checkins.length / this.itemsPerPage)
    },

    /**
     * Undouble checkins
     */
    unDouble(checkins) {
      let doubles = {}
      let newCheckinList = []
      let currentTime = this.$options.filters.timeToMinutes(
        new Date().getTime()
      )

      // Find doubles
      checkins.forEach((checkin, index) => {
        if (doubles[checkin.ProfileId]) {
          doubles[checkin.ProfileId].push(checkin)
        } else {
          doubles[checkin.ProfileId] = [checkin]
        }
      })

      for (let key in doubles) {
        if (doubles[key].length === 1) {
          // Add checkin to new checkin array
          newCheckinList.push(doubles[key][0])
        } else {
          // Figure out which one to take
          doubles[key] = doubles[key].slice().sort((a, b) => {
            // let stepUpOrDown = 0
            if (
              (currentTime > a.StartMinutes &&
                currentTime < a.EndMinutes &&
                a.StartMinutes < b.StartMinutes) ||
              ((currentTime < a.StartMinutes || currentTime > a.EndMinutes) &&
                a.StartMinutes < b.StartMinutes)
            ) {
              return 1
            } else {
              return -1
            }
          })
          newCheckinList.push(this.filterDouble(doubles[key]))
        }
      }
      return newCheckinList
    },

    /**
     * Filter double checkins of the same profile
     */
    filterDouble(doubleElements = []) {
      let temp = []
      // Check if checkin is on a location
      temp = this.checkedInOnLocation(doubleElements)

      // Check if checkin is on a event
      if (!temp.length) {
        temp = this.checkedInOnEvent(doubleElements)
      }

      // Check if checkin filled in his working on
      if (!temp.length) {
        temp = this.filledInWorkingOn(doubleElements)
      }
      return temp
    },

    /**
     * Check if checkin is checked in on an event
     */
    checkedInOnEvent(checkins = []) {
      let temp = []

      temp = checkins.filter((checkin, index) => {
        return checkin.EventId > 0
      })
      if (temp.length) {
        return temp[0]
      }
      return []
    },

    /**
     * Check if checkin is checked in on a location
     */
    checkedInOnLocation(checkins = []) {
      let temp = []

      temp = checkins.filter((checkin, index) => {
        return checkin.LocationId > 0
      })
      if (temp.length) {
        return temp[0]
      }
      return []
    },

    /**
     * Check if checkin is checked in on a location
     */
    filledInWorkingOn(checkins = []) {
      let temp = []

      temp = checkins.filter((checkin, index) => {
        return checkin.WorkingOn !== ''
      })
      if (temp.length) {
        return temp[0]
      } else {
        return checkins[0]
      }
    },

    /**
     * Show or hide filter drawer
     */
    showHideCheckinFilterDrawer() {
      this.showCheckinFiltersDrawer = !this.showCheckinFiltersDrawer
    }
  }
}
</script>

<style lang="scss" scoped>
.checkinProcess-holder {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.anchor-clear-filters {
  display: inline-block;
  margin-top: 5px;
}

.columns-checkins {
  @media screen and (max-width: 500px) {
    .column {
      width: 100%;
    }
  }
}
</style>
