<template>
  <nav
    class="pagination is-centered"
    role="navigation"
    aria-label="pagination"
  >
    <a
      v-if="showButtons && currentPage > 1"
      @click="gotoPage(currentPage - 1)"
      class="pagination-previous"
    >Previous</a>
    <a
      v-if="showButtons && currentPage < lastPageNumber"
      @click="gotoPage(currentPage + 1)"
      class="pagination-next"
    >Next page</a>

    <ul class="pagination-list">
      <li
        v-for="(pageNumber, index) in buildPagination()"
        :key="'p_'+index"
      >
        <a
          v-if="!isNaN(pageNumber)"
          @click="gotoPage(pageNumber)"
          class="pagination-link"
          :class="{ 'is-current': pageNumber === currentPage }"
        >{{pageNumber}}</a>
        <span
          v-if="isNaN(pageNumber)"
          class="pagination-ellipsis has-text-grey"
        >&hellip;</span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 0
    },
    gotoPage: {
      type: Function,
      required: true
    },
    itemsPerPage: {
      type: Number,
      default: 0
    },
    pageCount: {
      type: Number,
      default: 0
    },
    showButtons: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      pagination: [],
      lastPageNumber: 0
    }
  },

  methods: {
    /**
     * Buils pagination
     */
    buildPagination() {
      let page = this.currentPage
      let pageCount = this.pageCount
      let delta = 2
      let left = page - delta
      let right = page + delta + 1
      let result = []

      result = Array.from({ length: pageCount }, (v, k) => k + 1)
        .filter(i => i && i >= left && i < right)

      if (result.length > 1) {
        // Add first page and dots
        if (result[0] > 1) {
          if (result[0] > 2) {
            result.unshift('...')
          }
          result.unshift(1)
        }

        // Add dots and last page
        if (result[result.length - 1] < pageCount) {
          if (result[result.length - 1] !== pageCount - 1) {
            result.push('...')
          }
          result.push(pageCount)
        }
      }

      this.lastPageNumber = result.length - 1
      return result
    }
  }
}
</script>
